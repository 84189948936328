import React from 'react'
import './Figures.css'
import NumberCounter from 'number-counter'

const Figures = () => {
    return (
      <>
        <div className="figures">
          <div>
            <span>
              <NumberCounter start={100} end={140} delay="3" preFix="+" />
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter start={800} end={978} delay="3" preFix="+" />
            </span>
            <span>Members Joined</span>
          </div>
          <div>
            <span>
              <NumberCounter start={0} end={50} delay="3" preFix="+" />
            </span>
            <span>Fitness Program</span>
          </div>
        </div>
        <div className="hero-btns">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </>
    );
};

export default Figures