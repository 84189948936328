import React from "react";
import "./Join.css";

const Join = () => {
  return (
    <div className="join">
      <div className="left-join">
        <span></span>
        <div>
          <span className="stroke-text">READY TO</span>
          <span> LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text"> WITH US?</span>
        </div>
      </div>
      <div className="right-join">
        <form action="" className="email-form">
          <input type="email" name="user_email" placeholder="Enter Your Email here" />
          <button className="btn btn-join">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
