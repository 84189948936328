import React from 'react'
import { motion } from 'framer-motion';
import './Title.css'
const Title = () => {
    const transition = { type: 'tween', duration: 2 };
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className='title'>
            <div className='the-bst-ad'>
                <motion.div
                    initial={{ left: mobile ?'178px':'236px' }}
                    whileInView={{ left: '9px' }}
                    transition ={transition}
                />
                <span> the best fitness club in the world</span>
            </div>
            <motion.div
                className='hello'
                initial={{ right: '-18rem' }}
                whileInView={{ right: '0rem' }}
                transition = {{...transition , duration:1.6}}
            >
                Dev by : Abdallah Gaber Mohammed 
            </motion.div>
            <div className="hero-text">
                <div className="shape">
                    <span className='stroke-text'>shape </span><span>your</span>
                </div>
                <div>
                    <span>ideal body</span>
                </div>
                <div>
                    <span>being fit is one of the most requried things nowdays , so we will help you to bulid your body amazingly
                    </span>
                </div>
            </div>
        </div> 
    );
};
export default Title

