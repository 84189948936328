import React, { useState } from "react";
import "./Heading.css";
import Logo from "../../../assets/logo.png";
import Bars from "../../../assets/bars.png";
import { Link } from "react-scroll";
/* assests should be imported before using it in react*/

const Heading = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="header">
      <img src={Logo} alt="logo" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            background: "var(--appcolor)",
            padding: ".5rem",
            cursor: "pointer",
          }}
          onClick={() => setMenuOpened(true)}
        >
          <img src={Bars} alt="" style={{ width: "2rem", height: "1.7rem" }} />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link
              to="hero"
              activeClass="active"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Home Page
            </Link>
          </li>
          <li onClick={() => setMenuOpened(false)}>
            <Link
              to="progrmas"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              to="choose"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Why Us
            </Link>
          </li>
          <li>
            <Link
              to="plans"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Our Plans
            </Link>
          </li>
          <li>
            <Link
              to="testimonials"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Heading;
