import React from 'react'
import './Choose.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import nb from '../../assets/nb.png'
import nike from '../../assets/nike.png'
import adidas from '../../assets/adidas.png'
import tick from '../../assets/tick.png'

const Choose = () => {
  return (
      <div className='choose' id='choose'>
          <div className='left-side'>
              <div className='p1'><img src={image1} alt="" /></div>
              <div className='p2'>
                  <img src={image2} alt="" />
                  <div>
                      <img src={image3} alt="" />
                      <img src={image4} alt="" />
                  </div>
              </div>
          </div>
          <div className='right-side'>
              <span>Some Reseaons:</span>
              <div className='title'>
                  <span className='stroke-text'>why</span>
                  <span>choose us?</span>
              </div>
              <div  className='details'>
                  <div>
                      <img src={tick}alt="" />
                      <span>+140 Epert Coaches</span>
                  </div>
                  <div>
                      <img src={tick} alt="" />
                      <span>Train Smarter and Harder</span>
                  </div>
                  <div>
                      <img src={tick} alt="" />
                      <span>Free Program for New Members</span>
                  </div>
                  <div>
                      <img src={tick} alt="" />
                      <span>Relible Partner</span>
                      </div>
              </div>
              <div className='partners'>
                  <span className='prtns'>
                      our partners
                  </span>
                  <div className='tradeMarks'>
                      <img src={nb} alt="" />
                      <img src={adidas} alt="" />
                      <img src={nike} alt="" />
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Choose