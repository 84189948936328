import React from 'react'
import './RScomp.css'
import Heart from '../../../assets/heart.png'
import { motion } from 'framer-motion';

const RSComp = () => {
    const transition = { type: 'string', duration: 1 };
    return (
        <div className='right-hero-side'>
            <button className='btn btn-RS'>Join Now</button>
            <motion.div
                className='heart-rate'
                initial={{ right: '-1rem' }}
                whileInView={{ right: '4rem' }}
                transition = {transition}
            >
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>112 bpm</span>
            </motion.div>
        </div>
    );
};

export default RSComp