import './App.css';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Programs/Programs';
import Choose from './Components/Choose/Choose';
import Plans from './Components/Plans/Plans';
import Testimonials from './Components/Testimonials/Testimonials';
import Join from './Components/Join/Join';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <div className="App">
    {/* 
      our concet in thinking here is depending on buliding 
      application consist of many components and the hero
      component is the first components .
      the application has a general folder called components
      and all components are inside it.
    */}
      
      {/* Here are the components we have made  */}
      <Hero />
      <Programs />
      <Choose />
      <Plans />
      <Testimonials />
      <Join />
      <Footer />
     
    </div>
  );
}

export default App;
