import React from "react";
import "./Footer.css";
import Linkedin from "../../assets/linkedin.png";
import Github from "../../assets/github.png";
import Instgram from "../../assets/instagram.png";
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <hr />
      <div className="footer-imgs">
        <div className="socail-img">
          <img src={Github} alt="" />
          <img src={Linkedin} alt="" />
          <img src={Instgram} alt="" />
        </div>
        <div className="img-f">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="blur f-blur-1"></div>
      <div className="blur f-blur-2"></div>
    </div>
  );
};

export default Footer;
