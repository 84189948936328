import React from 'react'
import './Hero.css'
import Heading from './Heading/Heading';
import Title from './Title/Title';
import Figures from './Figures/Figures';
import RSComp from './RSComp/RSComp';
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Calories from '../../assets/calories.png'
import { motion } from 'framer-motion';

const Hero = () => {
  const transition = { type: "spring", duration: 1.2 };
    return (
      /*
        # our concept of thinking here is to divide the hero 
        component into two big divs.
        # the left hero div will contain the gray part and 
        its conent (Logo - Navbar - paragraph)
        # the right hero div will contain the orange part
        and its content (button , image)
        */
      <div className="hero" id='hero'>
        <div className="blur hero-blur"></div>
        {/* Here is the left side of hero component.*/}
        <div className="left-hero">
          <Heading />
          <Title />
          <Figures />
        </div>

        {/* Here is the right side of hero component.*/}
        <div className="right-hero">
          <RSComp />
        </div>

        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          initial={{ right: "13rem" }}
          whileInView={{ right: "22rem" }}
          transition={{...transition , duration : 2}}
          src={hero_image_back}
          alt=""
          className="hero-back"
        />
        <motion.div
          className="calories"
          initial={{ left: "28rem" }}
          whileInView={{ left: "33rem" }}
          transition={transition}
        >
          <img src={Calories} alt="" className="img" />
          <div>
            <span>calories burned</span>
            <span>200kcal</span>
          </div>
        </motion.div>
      </div>
    );
};

export default Hero
